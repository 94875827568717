body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--slantedFont);
  /* margin-bottom: 1.25rem; */
  letter-spacing: var(--mainSpacing);
}
/* p {
  margin-bottom: 1.25rem;
} */
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--mainBlack);
}
img {
  width: 100%;
  display: block;
}

/* variables */
:root {
  --primaryLightColor: #d4e6a5;
  --primaryColor: #476a2e;
  --primaryDarkColor: #c02c03;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBackground: #f1f5f8;
  --mainOverlay: rgba(35, 10, 36, 0.4);
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #afafaf;
  --mainRed: #bd0303;
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  --clr-primary-1: hsl(21, 91%, 17%);
  --clr-primary-2: hsl(21, 84%, 25%);
  --clr-primary-3: hsl(21, 81%, 29%);
  --clr-primary-4: hsl(21, 77%, 34%);
  --clr-primary-5: hsl(21, 62%, 45%);
  --clr-primary-6: hsl(21, 57%, 50%);
  --clr-primary-7: hsl(21, 65%, 59%);
  --clr-primary-8: hsl(21, 80%, 74%);
  --clr-primary-9: hsl(21, 94%, 87%);
  --clr-primary-10: hsl(21, 100%, 94%);
  --mainTransition: all 0.3s linear;
  --mainSpacing: 0.3rem;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --mainBorderRadius: 0.25rem;
  --smallWidth: 85vw;
  --maxWidth: 40rem;
  --fullWidth: 1170px;
}

/* Nav */

.navbar {
  background-color: #f1f5f8;
  z-index: 2;
}
.nav-links {
  height: 0;
  overflow: hidden;
  transition: 0.25s;
}
.show-links {
  height: 10rem;
  transition: 0.25s;
}
.links li {
  color: var(--clr-grey-3);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
  cursor: pointer;
}
.links li:hover {
  cursor: pointer;
}
.links li a {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--mainBlack);
  transition: var(--mainTransition);
}
.links li a:hover {
  color: var(--clr-grey-5);
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.nav-toggle {
  font-size: 1.5rem;
  border: none;
  outline: none;
}
.nav-toggle:hover {
  cursor: pointer;
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  .nav-toggle {
    display: none;
  }

  .navbar {
    display: flex;
    align-items: center;
    height: 5rem;
  }
  .nav-center {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  .nav-center h2 {
    margin-right: 4rem;
  }
  .nav-links {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .nav-links li {
    text-transform: capitalize;
    display: inline-block;
    font-weight: bold;
    margin-right: 0.5rem;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
    transition: var(--mainTransition);
    cursor: pointer;
  }
}

/* hero */
.hero {
  position: relative;
  min-height: calc(100vh - 80px);

  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.hero::before {
  content: '';
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./images/pexels-photo-160107.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
}
.hero-center {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  z-index: 1;
  padding: 2rem 0 2rem 0;
}
.hero-info {
  text-align: center;
}

.hero-info h1 {
  margin-bottom: 1.5rem;
  font-size: 3rem;
}
.hero-info h4 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: rgba(31, 30, 30, 0.835);
}
.hero-info img {
  max-width: 300px;
  border-radius: 50%;
  border: #222 0.5rem solid;
  margin-bottom: 1rem;
  margin: 0 auto;
}
.hero-skills {
  display: grid;
  justify-content: center;
}
.btn {
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  background: var(--clr-grey-5);
  cursor: pointer;
  transition: var(--transition);
  margin-top: 1rem;
  margin-right: 2rem;
}
.btn:hover {
  background: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  .hero {
    position: relative;
    min-height: calc(100vh - 80px);

    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .hero::before {
    content: '';
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(./images/pexels-photo-160107.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }
  .hero-center {
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    z-index: 1;
  }
  .hero-info {
    text-align: center;
  }

  .hero-info h1 {
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }
  .hero-info h4 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: rgba(31, 30, 30, 0.835);
  }
  .hero-info img {
    max-width: 500px;
    max-height: 600px;
    border-radius: 50%;
    border: #222 0.5rem solid;
    margin-bottom: 1rem;
    margin: 0 auto;
  }
  .hero-skills {
    display: grid;
    justify-content: center;
  }
  .btn {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: var(--mainTransition);
    background: var(--clr-grey-5);
    cursor: pointer;
    transition: var(--transition);
    margin-top: 1rem;
    margin-right: 2rem;
    color: #222;
  }
  .btn:hover {
    color: var(--offWhite);
  }
}
/* slider */
.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 2rem;
  text-transform: capitalize;
}
.title span {
  font-size: 0.85em;
  color: var(--clr-primary-5);
  margin-right: 1rem;
  font-weight: 700;
}
.slider-skill {
  font-size: 1.5rem;
  margin-top: 3rem;
}
@media screen and (min-width: 920px) {
  .slider-skill {
    font-size: 2rem;
    margin-top: 3rem;
  }
  .title h2 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 1080px) {
  .slider-skill {
    font-size: 3rem;
    margin-top: 2rem;
  }
  .title h2 {
    font-size: 5rem;
  }
}
.section {
  margin-top: 4rem;
}
.section-center {
  border: var(--clr-grey-3) 0.5rem solid;
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-top: 4rem;
  /* width: 80vw; */
  height: 150px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}
article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}
.prev {
  position: absolute;
  top: 25%;
  font-size: 2rem;
  padding: 1rem;
  background-color: transparent;
  border: none;
  display: block;
  outline: none;
}
.next {
  position: absolute;
  right: 0;
  top: 25%;
  font-size: 2rem;

  padding: 1rem;
  background-color: transparent;
  border: none;
  display: block;
  outline: none;
}
.next:hover {
  cursor: pointer;
  color: #fff;
}
.prev:hover {
  cursor: pointer;
  color: #fff;
}
/* projects */
.projects-section {
  height: auto;
  background-color: var(--clr-grey-9);
  text-align: center;
  position: relative;
  padding-bottom: 2rem;
}
.projects-section h1 {
  padding-top: 1rem;
  font-size: 3rem;
  text-transform: capitalize;
}
.projects-center {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  width: 90vw;
  margin: 0 auto;
  gap: 2rem;
  margin-top: 2rem;
}
.single-project {
  background-color: var(--offWhite);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-bottom: 2rem;
}
.single-project-container {
  height: 10rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.single-project-container h3 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.single-project-footer {
  padding-bottom: 0.5rem;
  height: 2.5rem;
}
.single-project-btn {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--clr-grey-6);
  padding: 0.5rem;
  transition: var(--mainTransition);
}
.single-project-btn:hover {
  color: var(--mainWhite);
}
.single-project:hover {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
}
.single-project img {
  height: 350px;
}
/* about section */
.about-section {
  height: auto;
  background-color: var(--clr-grey-10);
  text-align: center;
  padding-bottom: 4rem;
}
.about-section h2 {
  padding-top: 1rem;
  font-size: 3rem;
  text-transform: capitalize;
}
.about-info {
  width: 60vw;
  margin: 0 auto;
  margin-top: 3rem;
  font-size: 2rem;
}
/* constacs section */
.contacts-section {
  height: auto;
  background-color: var(--clr-grey-6);
  text-align: center;
  padding-bottom: 3rem;
}
.contacts-section h2 {
  padding-top: 1rem;
  text-transform: capitalize;
  font-size: 3rem;
}
.contacts-info {
  font-size: 2rem;
  margin: 0 auto;
  margin-top: 3rem;
}

.single-info {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-info p {
  margin-left: 1rem;
  color: #222;
  font-size: 2rem;
  padding: 0;
}
/* footer */
.footer {
  padding-bottom: 2rem;
  background: #222;
  display: grid;
  place-items: center;
  color: var(--offWhite);
}
.footer .social-icons {
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.footer .social-icon {
  color: var(--clr-grey-5);
  font-size: 2rem;
  transition: var(--mainTransition);
}
.footer .social-icon:hover {
  cursor: pointer;
  color: var(--offWhite);
}
.footer p {
  font-size: 1.75rem;
  text-transform: capitalize;
}
